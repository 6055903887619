<template>
    <div>
        <!-- Spinner Start -->
        <div id="spinner"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            v-if="isLoadingBlade"
        >
            <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;"></div>
        </div>
        <!-- Spinner End -->


        <!-- Navbar Start -->
        <div class="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s" v-if="params">
            <div class="top-bar row gx-0 align-items-center d-none d-lg-flex">
                <div class="col-lg-6 px-5 text-start">
                    <small>
                        <i class="fa fa-map-marker-alt text-primary me-2"></i>
                        {{ params.address }}
                    </small>
                    <small class="ms-4">
                        <i class="fa fa-clock text-primary me-2"></i>
                        {{ params.timing }}
                    </small>
                </div>
                <div class="col-lg-6 px-5 text-end">
                    <small>
                        <i class="fa fa-envelope text-primary me-2"></i>
                        {{ params.email }}
                    </small>
                    <small class="ms-4">
                        <i class="fa fa-phone-alt text-primary me-2"></i>
                        {{ params.phone }}
                    </small>
                </div>
            </div>

            <nav class="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                <a href="index.html" class="navbar-brand ms-4 ms-lg-0">
                    <img src="/logo.png" :alt="params.name"/>  
                    <!-- <h1 class="display-5 text-primary m-0">Finanza</h1> -->
                </a>
                <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav ms-auto p-4 p-lg-0">
                        <router-link to="/" class="nav-item nav-link active">
                            Home
                        </router-link>
                        <div 
                            class="nav-item dropdown"
                            v-for="menu in menus"
                            :key="menu.id"
                            v-show="menu.sub_menus && menu.sub_menus.length > 0"
                        >
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                {{ menu.title }}
                            </a>
                            <div class="dropdown-menu border-light m-0">
                                <router-link 
                                    :to="'/'+menu.route + '/' +sub_menu.route"
                                    class="dropdown-item"
                                    v-for="sub_menu in menu.sub_menus"
                                    :key="sub_menu.id"
                                >
                                    {{ sub_menu.title }}
                                </router-link>
                            </div>
                        </div>
                        <router-link
                            v-for="menu in menus"
                            :key="menu.id"
                            v-show="menu.sub_menus && menu.sub_menus.length == 0 && (menu.page 
                                    || (menu.contents && menu.contents.length > 0))"
                            :to="'/'+menu.route"
                            class="nav-item nav-link"
                        >
                            {{ menu.title }}
                        </router-link>
                        <router-link to="/contact" class="nav-item nav-link">
                            Contact
                        </router-link>
                    </div>
                    <div class="d-none d-lg-flex ms-2">
                        <a 
                            class="btn btn-light btn-sm-square rounded-circle ms-3" 
                            :href="params.facebook"
                            target="_blank"
                        >
                            <small class="fab fa-facebook-f text-primary"></small>
                        </a>
                        <a 
                            class="btn btn-light btn-sm-square rounded-circle ms-3" 
                            :href="params.twitter"
                            target="_blank"
                        >
                            <small class="fab fa-twitter text-primary"></small>
                        </a>
                        <a 
                            class="btn btn-light btn-sm-square rounded-circle ms-3" 
                            :href="params.linkedin"
                            target="_blank"
                        >
                            <small class="fab fa-linkedin-in text-primary"></small>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
        <!-- Navbar End -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'Header',
        props: ['menus', 'params', 'isLoadingBlade'],
        computed: {
            ...mapGetters(['getsiteName'])
        }
    }
</script>

<style lang="scss" scoped>

</style>