<template>
    <div>
        <!-- Footer Start -->
        <div class="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
            <div class="container py-5" v-if="params">
                <div class="row g-5">
                    <div class="col-lg-5 col-md-6">
                        <h4 class="text-white mb-4">Our Head Office</h4>
                        <p class="mb-2">
                            <i class="fa fa-map-marker-alt me-3"></i>
                            {{ params.address }}
                        </p>
                        <p class="mb-2">
                            <i class="fa fa-phone-alt me-3"></i>
                            {{ params.phone }}
                        </p>
                        <p class="mb-2">
                            <i class="fa fa-envelope me-3"></i>
                            {{ params.email }}
                        </p>
                        <div class="d-flex pt-2">
                            <a 
                                class="btn btn-square btn-outline-light rounded-circle me-2" 
                                :href="params.facebook"
                                target="_blank"
                            >
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a 
                                class="btn btn-square btn-outline-light rounded-circle me-2" 
                                :href="params.twitter"
                                target="_blank"
                            >
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a 
                                class="btn btn-square btn-outline-light rounded-circle me-2" 
                                :href="params.linkedin"
                                target="_blank"
                            >
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-white mb-4">Quick Links</h4>
                        <router-link class="btn btn-link" to="/">Home</router-link>
                        <router-link class="btn btn-link" to="/contact">Contact Us</router-link>
                        <router-link class="btn btn-link" to="/blog">Blog</router-link>
                        <router-link class="btn btn-link" to="/privacy-policy">Privacy Policy</router-link>
                        <router-link class="btn btn-link" to="/get-quote">Get Quote</router-link>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <h4 class="text-white mb-4">Newsletter</h4>
                        <p>Subscribe for our Newsletters</p>
                        <div class="position-relative w-100">
                            <input class="form-control bg-white border-0 w-100 py-3 ps-4 pe-5" type="text"
                                placeholder="Your email">
                            <button type="button"
                                class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End -->


        <!-- Copyright Start -->
        <div class="container-fluid copyright py-4">
            <div class="container" v-if="params">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <router-link class="border-bottom" to="/">{{ params.name }}</router-link>, 
                        All Right Reserved.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                        Designed By 
                        <a class="border-bottom" href="https://maspatconsulting.com" target="_blank">
                            MASPAT Consulting
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Copyright End -->


        <!-- Back to Top -->
        <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i
                class="bi bi-arrow-up"></i></a>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        props: ['params', 'services'],
        computed: {
            contents() {
                return this.services && this.services.contents ? this.services.contents : false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>