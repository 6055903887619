const rootUrl = 'https://admin.visiontrustbrokers.com/api';
const siteName = 'Vision Trust Insurance Brokers Limited';
// homepage Urls
const getHomepageDataUrl = rootUrl+'/get-homepage-data';
const getOtherContentPageUrl = rootUrl+'/get-other-content';
const paymentTokenUrl = rootUrl+'/get-payment-token';
const updatePaymentTransactionUrl = rootUrl+'/update-payment-transaction';
// pages urls
const getMenuPageUrl = rootUrl+'/get-menu-page';
const getOtherContentPagesUrl =  rootUrl+'/get-other-content-pages';
const getMainMenuContentUrl = rootUrl+'/get-main-menu-content';
const getMainMenuContentsUrl = rootUrl+'/get-main-menu-contents';
const getOtherContentSinglePageUrl = rootUrl+'/get-other-content-page';
const updateContactUrl = rootUrl+'/update-contact';
const getSubMenuPageContentUrl = rootUrl+'/get-sub-menu-page-contents';
const getMenuRoutePageContentUrl = rootUrl+'/get-menu-page-contents';
const sendQuoteUrl = rootUrl+'/send-quote';


// Authorization Key 
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcY653geddeSRRmYOwvjce1';
const paginateNo = 9;
const recaptchaKey = '6LcQZNcjAAAAAN4NLTIvVh-DY8FdO5R2h0fXL92F'; 

const state = {
    sendQuoteUrl: sendQuoteUrl,
    getMenuRoutePageContentUrl: getMenuRoutePageContentUrl,
    getSubMenuPageContentUrl: getSubMenuPageContentUrl,
    updatePaymentTransactionUrl: updatePaymentTransactionUrl,
    paymentTokenUrl: paymentTokenUrl,
    updateContactUrl: updateContactUrl,
    getOtherContentSinglePageUrl: getOtherContentSinglePageUrl,
    getMainMenuContentsUrl: getMainMenuContentsUrl,
    getMainMenuContentUrl: getMainMenuContentUrl,
    getOtherContentPagesUrl: getOtherContentPagesUrl,
    getMenuPageUrl: getMenuPageUrl,
    getOtherContentPageUrl: getOtherContentPageUrl,
    getHomepageDataUrl: getHomepageDataUrl,
    authorizationToken: authorizationToken
    
};

const getters = {
    getsiteName: () => siteName,
    paginateNo: () => paginateNo,
    recaptchaKey: () => recaptchaKey
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}