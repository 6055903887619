<template>
    <div>
        <div v-if="! errorPage">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
            />
            <!-- end:header-top --> 

            <!-- Page Header Start -->
            <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="container">
                    <h1 class="display-3 mb-4 animated slideInDown">Contact Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <!-- Page Header End -->

            <!-- Contact Start -->
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Contact Us</p>
                            <h1 class="display-5 mb-4">If You Have Any Query, Please Contact Us</h1>
                            <form @submit.prevent="submitContact">
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input 
                                                type="text" class="form-control" id="name" 
                                                placeholder="Your Name"
                                                v-model="record.name"
                                            >
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input 
                                                type="email" class="form-control" id="email" 
                                                placeholder="Your Email"
                                                v-model="record.email"
                                            >
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">
                                            <input 
                                                type="text" class="form-control" id="phone" 
                                                placeholder="Your Phone Number"
                                                v-model="record.phone"
                                            >
                                            <label for="phone">Your Phone Number</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea 
                                                class="form-control" placeholder="Leave a message here" id="message"
                                                style="height: 100px"
                                                v-model="record.message"
                                            ></textarea>
                                            <label for="message">Message</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button 
                                            class="btn btn-primary py-3 px-5" type="submit"
                                        >
                                            {{ this.isloading ? 'Please Wait...' : 'Send Messege' }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style="min-height: 450px;">
                            <div class="position-relative rounded overflow-hidden h-100">
                                <!-- address map included here -->
                                <iframe 
                                    :src="params.gMapSrc" 
                                    width="100%" height="450" 
                                    style="border:0;" allowfullscreen="" 
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row" v-if="params">
                    <div class="col-lg-12"><hr></div>
                    <div class="col-lg-6" v-if="(params.branchTitleOne != '')">                                
                        <h3 class="text-muted">{{ params.branchTitleOne }}</h3>
                        <span>
                            <a 
                                :href="params.branchMapOne" 
                                target="_blank" 
                                class="text-muted"
                            >
                                <i class="icofont-location-pin mr-2"></i>
                                {{ params.branchAddressOne }}
                            </a>
                            <br>
                            <a :href="'tel:'+params.branchPhoneOne">
                                <i class="icofont-phone-circle"></i>
                                <span 
                                    style="margin-left: 10px;" 
                                    class="text-muted"
                                >
                                    {{ params.branchPhoneOne }}
                                </span>
                            </a>
                        </span>
                    </div>
                    <div class="col-lg-6" v-if="(params.branchTitleTwo != '')">
                        <h3 class="text-muted">{{ params.branchTitleTwo }}</h3>
                        <span>
                            <a 
                                :href="params.branchMapTwo" 
                                target="_blank"
                                class="text-muted"
                            >
                                <i class="icofont-location-pin mr-2"></i>
                                {{ params.branchAddressTwo }}
                            </a>
                            <br>
                            <a :href="'tel:'+params.branchPhoneTwo">
                                <i class="icofont-phone-circle"></i>
                                <span 
                                    style="margin-left: 10px;"
                                    class="text-muted"
                                >
                                    {{ params.branchPhoneTwo }}
                                </span>
                            </a>
                        </span>                                
                    </div>
                    <div class="col-lg-12"><hr></div>
                    <div class="col-lg-6" v-if="(params.branchTitleThree != '')">                                
                        <h3 class="text-muted">{{ params.branchTitleThree }}</h3>
                        <span>
                            <a 
                                :href="params.branchMapThree" 
                                target="_blank" 
                                class="text-muted"
                            >
                                <i class="icofont-location-pin mr-2"></i>
                                {{ params.branchAddressThree }}
                            </a>
                            <br>
                            <a :href="'tel:'+params.branchPhoneThree">
                                <i class="icofont-phone-circle"></i>
                                <span 
                                    style="margin-left: 10px;" 
                                    class="text-muted"
                                >
                                    {{ params.branchPhoneThree }}
                                </span>
                            </a>
                        </span>
                    </div>
                    <div class="col-lg-6" v-if="(params.branchTitleFour != '')">
                        <h3 class="text-muted">{{ params.branchTitleFour }}</h3>
                        <span>
                            <a 
                                :href="params.branchMapFour"  
                                target="_blank"
                                class="text-muted"
                            >
                                <i class="icofont-location-pin mr-2"></i>
                                {{ params.branchAddressFour }}
                            </a>
                            <br>
                            <a :href="'tel:'+params.branchPhoneFour">
                                <i class="icofont-phone-circle"></i>
                                <span 
                                    style="margin-left: 10px;"
                                    class="text-muted"
                                >
                                    {{ params.branchPhoneFour }}
                                </span>
                            </a>
                        </span>                                
                    </div>
                </div>
            </div>
            <!-- Contact End -->

            <!-- Footer included here -->
            <Footer 
                :params="params"
                :services="services"
                v-if="! isLoadingBlade"
            />
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import ErrorPage from './ErrorPage.vue';

    export default {    
        name: 'ContactPage',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        components: {
            Header,
            Footer,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: [],
                record: {
                    name: null,
                    email: null,
                    message: null,
                    phone: null,
                    token: null
                },
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'updateContact']),
            submitContact() {
                let check = this.validateInputs();
                if(check) {
                    this.isloading = true;
                    this.$recaptcha("login").then(token => {
                    this.record.token = token;
                    this.updateContact(this.record).then((res) => {// get homepage data
                            this.isloading = false;
                            if(res.status == 200  && res.data.status) { // set data
                                this.emptyRecord();
                                $('.contact__msg').css('display', 'block');
                                setTimeout(() => { $('.contact__msg').css('display', 'none'); }, 5000)
                            } else {
                                this.isLoadingBlade = false;
                                swal.fire('Error Occurred', res.data.msg, 'error');
                            }
                        }).
                        catch(() => {
                            this.isLoadingBlade = false;
                            this.isloading = false;
                            let msg = 'Error occurred while sending message, kindly reload page and try again...';
                            swal.fire('Error Occurred', msg, 'error');
                        });
                    }).catch(() => {
                        this.isloading = false;
                        let msg = 'Error occurred while sending message, kindly reload page and try again...';
                        swal.fire('Error Occurred', msg, 'error');
                    });
                }
            },
            validateInputs() {
                let status = this.record.name && this.record.email && this.record.message;
                if(status) {
                    return true
                } else {
                    let msg = 'Empty Field(s), fill the necessary fields and try again...';
                    swal.fire('Error Occurred', msg, 'error');
                    return false;
                }
            },
            emptyRecord() {
                return this.record = {
                    name: null,
                    email: null,
                    message: null,
                    phone: null,
                    token: null
                };
            },
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services;// get services
                setTimeout(() => { this.isLoadingBlade = false; }, 1000)
            }
        },
        created() {
            this.loadHomepage(); // get page
        }
    }
</script>

<style lang="scss" scoped>

</style>