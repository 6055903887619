<template>
    <div>
        <!-- this is the header -->
        <Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
        />

        <!-- this is the slider -->
        <Slider 
            :sliders="sliders"
            v-if="! isLoadingBlade"
        />

        <!-- this is the About us -->
        <About 
            :params="params"
            v-if="! isLoadingBlade"
        />

        <!-- our services included here -->
        <Services 
            :services="services"
            v-if="! isLoadingBlade"
        /> 

        <!-- this is the Quote Form -->
        <QuoteForm 
            :params="params"
            :services="services"
            v-if="! isLoadingBlade"
        />

        <!-- our Testimonials included here -->
        <Testimonials 
            :testimonials="testimonials"
            :params="params"
            v-if="! isLoadingBlade"
        />

        <!-- Footer included here -->
        <Footer 
            :params="params"
            :services="services"
            v-if="! isLoadingBlade"
        />
        
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Slider from '../layouts/sliders/Slider.vue'
    import About from '../layouts/homepage/About.vue'
    import Services from '../layouts/homepage/Services.vue'
    import QuoteForm from '../layouts/homepage/QuoteForm.vue'
    import Testimonials from '../layouts/homepage/Testimonials.vue'
    import Footer from '../layouts/Footer.vue'

    export default {
        name: 'Homepage',
        components: {
            Header,
            Slider,
            About,
            Services,
            QuoteForm,
            Testimonials,
            Footer
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                sliders: [],
                services: [],
                testimonials: [],
                partners: []
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.sliders = data.sliders;// set sliders
                this.services = data.services;// get services
                this.partners = data.partners;// get partners
                this.testimonials = data.testimonials; // get testimonials
                setTimeout(() => { this.isLoadingBlade = false; }, 2000)
            }
        },
        created() {
            this.loadHomepage();
        }
    }
</script>

<style lang="scss" scoped>

</style>