<template>
    <div 
        class="row mt-5"
        v-if="pagination.hasPagination"
    >
        <div class="col-lg-8">
            <nav class="pagination py-2 d-inline-block">
                <div class="nav-links">
                    <a 
                        :class="'page-numbers '+ (link.active ? ' current' : '')"
                        href="javascript:void(0)"
                        v-for="(link, index) in pagination.links"
                        :key="index"
                        @click="paginate((link.active ? null : link.url))"
                        :disabled="(link.url ? '' : true)"
                    >
                        <span 
                            v-if="index == 0"
                            
                        >&laquo;</span>
                        <span 
                            v-if="((index != 0) && (index+1 != pagination.links.length))"
                        >{{ link.label }}</span>
                        <span 
                            v-if="(index+1 == pagination.links.length)"
                        >&raquo;</span>
                    </a>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: ['paginate', 'pagination']
    }
</script>

<style lang="scss" scoped>

</style>