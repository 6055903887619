<template>
    <div>
        <!-- 404 Start -->
        <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                        <h1 class="display-1">404</h1>
                        <h1 class="mb-4">Page Not Found</h1>
                        <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to
                            our home page?</p>
                        <router-link
                            to="/"
                            class="btn btn-primary py-3 px-5"
                        >
                            Go Back To Home
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    <!-- 404 End -->
    </div>
    
</template>

<script>
    export default {
        name: 'ErrorPage'
    }
</script>

<style lang="scss" scoped>

</style>