<template>
    <!-- Callback Start -->
    <div class="container-fluid callback my-5 pt-quote">
        <div class="container pt-5">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="bg-white border rounded p-4 p-sm-5 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
                            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                                Get In Touch
                            </p>
                            <h1 class="display-5 mb-5">Get A Quote</h1>
                        </div>
                        <div class="row g-3">
                            <div class="col-sm-6">
                                <div class="form-floating">
                                    <select class="form-control" v-model="record.type" required>
                                        <option 
                                            :value="null" 
                                            disabled
                                        >
                                            Choose Insurance Type
                                        </option>
                                        <option
                                            v-for="product in products"
                                            :key="product.id"
                                            :value="product.slug"
                                        >
                                            {{ product.title }}
                                        </option>
                                        <option value="others">Others</option>
                                    </select>
                                    <label for="name">Insurance Type</label> 
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-floating">
                                    <input 
                                        type="text" class="form-control" id="name" 
                                        placeholder="Your Name" v-model="record.name"
                                    >
                                    <label for="name">Your Name</label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-floating">
                                    <input 
                                        type="email" class="form-control" id="mail" 
                                        placeholder="Your Email" v-model="record.email"
                                    >
                                    <label for="mail">Your Email</label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-floating">
                                    <input 
                                        type="text" class="form-control" id="mobile" 
                                        placeholder="Your Phone Number" v-model="record.phone"
                                    >
                                    <label for="mobile">Your Phone Number</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a message here" id="message"
                                        style="height: 100px" v-model="record.message"></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn btn-primary w-100 py-3" @click="submitQuote">
                                    {{ (this.isloading ? 'Please Wait...' : 'Get A Quote') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Callback End -->
</template>

<script>
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import { mapActions } from 'vuex';

    export default {
        name: 'QuoteForm',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        props: ['params', 'services'],
        data() {
            return {
                record: {
                    type: null,
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                isloading: false
            }
        },
        computed: {
            products() {
                return this.services && this.services.contents ? this.services.contents : [];
            }
        },
        methods: {
            ...mapActions(['sendQuote']),
            submitQuote() {                
                let check = this.validateInputs();
                if(check) {
                    this.isloading = true;
                    this.$recaptcha("login").then(token => {
                        this.record.token = token;
                        this.sendQuote(this.record).then((res) => {// get homepage data
                            this.isloading = false;
                            if(res.status == 200  && res.data.status) { // set data
                                this.emptyRecord();
                                swal.fire('Quote Sent!', res.data.msg, 'success');
                            } else {
                                this.isLoadingBlade = false;
                                swal.fire('Error Occurred', res.data.msg, 'error');
                            }
                        }).
                        catch(() => {
                            this.isLoadingBlade = false;
                            this.isloading = false;
                            let msg = 'Error occurred while sending quote, kindly reload page and try again...';
                            swal.fire('Error Occurred', msg, 'error');
                        });
                    }).catch(() => {
                        this.isloading = false;
                        let msg = 'Error occurred while sending quote, kindly reload page and try again...';
                        swal.fire('Error Occurred', msg, 'error');
                    });
                }                
            },
            validateInputs() {
                let status = this.record.type && this.record.name && this.record.email && this.record.message;
                if(status) {
                    return true
                } else {
                    let msg = 'Empty Field(s), fill the necessary fields and try again...';
                    swal.fire('Error Occurred', msg, 'error');
                    return false;
                }
            },
            emptyRecord() {
                return this.record = {
                    type: null,
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                };
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>