<template>
    <div v-if="page && category && content">
        <!-- Page Header Start -->
        <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div class="container">
                <h1 class="display-3 mb-4 animated slideInDown">{{ category.title }}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item" v-if="content && content.category">
                            <a href="#">{{ content.category.title }}</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ category.title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->

        <!-- About Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-4 align-items-end mb-4" v-if="category && page">
                    <div 
                        :class="'col-lg-12 wow fadeInUp'" 
                        data-wow-delay="0.3s"
                    >
                        <h1 class="display-5 mb-4">{{ category.title }}</h1>
                        <p class="mb-4" v-html="page.content"></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->

        <!-- Projects Start -->
        <div class="container-xxl py-5" v-if="records && records.data && records.data.length > 0">
            <div class="container">
                <div class="owl-carousel project-carousel wow fadeInUp" data-wow-delay="0.1s">
                    <div 
                        class="project-item pe-5 pb-5"
                        v-for="record in records.data"
                        :key="record.id"
                    >
                        <div class="project-img mb-3">
                            <img 
                                class="img-fluid rounded" 
                                v-if="record.image"
                                :src="record.image.url" 
                                :alt="record.image.description"  
                                style="width: 350px; height: 250px;"
                            >
                            <router-link
                                :to="'/menu/' + category.route + '/' + record.slug"
                            >
                                <i class="fa fa-link fa-3x text-primary"></i>
                            </router-link>
                        </div>
                        <div class="project-title">
                            <h4 class="mb-0">{{ record.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Projects End -->

    </div>
</template>

<script>
    export default {
        name: 'MixedTemplate',
        props: ['content', 'paginate'],
        computed: {
            category() {
                return this.content && this.content.menu ? this.content.menu : {};
            },
            page() {
                return this.content && this.content.menu && this.content.menu.page 
                        ? this.content.menu.page : {};
            },
            records() {
                return this.content && this.content.records ? this.content.records : [];
            }
        },
        methods: {
            setData() {
                setTimeout(() => { this.loadScripts(); }, 100)
            },
            loadScripts() {
                // Project carousel
                $(".project-carousel").owlCarousel({
                    autoplay: true,
                    smartSpeed: 1000,
                    margin: 25,
                    loop: true,
                    center: true,
                    dots: false,
                    nav: true,
                    navText : [
                        '<i class="bi bi-chevron-left"></i>',
                        '<i class="bi bi-chevron-right"></i>'
                    ],
                    responsive: {
                        0:{
                            items:1
                        },
                        576:{
                            items:1
                        },
                        768:{
                            items:2
                        },
                        992:{
                            items:3
                        }
                    }
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style lang="scss" scoped>

</style>