<template>
    <!-- Service Start -->
    <div class="container-xxl service py-5" v-if="services && services.category">
        <div class="container">
            <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
                <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                    {{ services.category.title }}
                </p>
                <h1 class="display-5 mb-5">{{ services.category.description }}</h1>
            </div>
            <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s" v-if="products && products.length > 0">
                <div class="col-lg-4">
                    <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                        <button 
                            v-for="(product, index) in products"
                            :key="index"
                            :class="'nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 ' 
                                + (index == 0 ? 'active' : '')"
                            data-bs-toggle="pill" :data-bs-target="'#tab-pane-' + index" type="button">
                            <h5 class="m-0">
                                <i class="fa fa-bars text-primary me-3"></i>
                                {{ product.title }}
                            </h5>
                        </button>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="tab-content w-100">
                        <div 
                            v-for="(record, index) in products"
                            :key="index"
                            :class="'tab-pane fade show ' + (index == 0 ? 'active' : '')" 
                            :id="'tab-pane-' + index"
                        >
                            <div class="row g-4">
                                <div class="col-md-6" style="min-height: 350px;">
                                    <div class="position-relative h-100">
                                        <img 
                                            class="position-absolute rounded w-100 h-100" 
                                            :src="record.image.url"
                                            :alt="record.image.description" 
                                            style="object-fit: cover;">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <h3 class="mb-4">Years Of Experience In Insurance</h3>
                                    <p class="mb-4" v-html="record.content"></p>
                                    <!-- <p><i class="fa fa-check text-primary me-3"></i>Secured Loans</p>
                                    <p><i class="fa fa-check text-primary me-3"></i>Credit Facilities</p>
                                    <p><i class="fa fa-check text-primary me-3"></i>Cash Advanced</p> -->
                                    <router-link
                                        :to="'/pages/' + services.category.slug + '/' + record.slug"
                                        class="btn btn-primary py-3 px-5 mt-3"
                                    >
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->
</template>

<script>
    export default {
        name: 'Services',
        props: ['services'],
        computed: {
            products() {// get four services
                return this.services && this.services.contents ? this.services.contents.slice(0, 4) : [];
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>