<template>
    <!-- Testimonial Start -->
    <div class="container-xxl py-5">
        <div class="container" v-if="category">
            <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
                <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                    {{ category.title }}
                </p>
                <h1 class="display-5 mb-5">{{ category.description }}</h1> 
            </div>
            <div 
                class="owl-carousel testimonial-carousel wow fadeInUp" 
                data-wow-delay="0.1s" 
                v-if="contents && contents.length > 0"
            >
                <div 
                    class="testimonial-item"
                    v-for="record in contents"
                    :key="record.id"
                >
                    <div class="testimonial-text border rounded p-4 pt-5 mb-5">
                        <div class="btn-square bg-white border rounded-circle">
                            <i class="fa fa-quote-right fa-2x text-primary"></i>
                        </div>
                        <span v-html="record.content"></span>
                    </div>
                    <img 
                        class="rounded-circle mb-3" 
                        :src="record.image.url" 
                        :alt="record.image.description" 
                    >
                    <h4>{{ record.description }}</h4>
                    <span>{{ record.title }}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonial End -->
</template>

<script>
    export default {
        name: 'Testimonials',
        props: ['testimonials', 'params'],
        computed: {
            category() {
                return this.testimonials && this.testimonials.category ? this.testimonials.category : false;
            },
            contents() {
                return this.testimonials && this.testimonials.contents ? this.testimonials.contents : false;
            }
        },
        methods: {
            setData() {
                setTimeout(() => { this.loadScripts(); }, 100)
            },
            loadScripts() {
                // Testimonials carousel
                $(".testimonial-carousel").owlCarousel({
                    autoplay: true,
                    smartSpeed: 1000,
                    center: true,
                    margin: 24,
                    dots: true,
                    loop: true,
                    nav : false,
                    responsive: {
                        0:{
                            items:1
                        },
                        576:{
                            items:1
                        },
                        768:{
                            items:2
                        },
                        992:{
                            items:3
                        }
                    }
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>
 
<style lang="scss" scoped>

</style>