import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from "vue-recaptcha-v3"

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueReCaptcha, { siteKey: "6LcQZNcjAAAAAN4NLTIvVh-DY8FdO5R2h0fXL92F" })
app.mount('#app')
