<template>
    <div>
        <div class="row" v-if="! errorPage">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
            />
            <!-- end:header-top -->
            

            <PageTemplate
                :record="content.menu.page"
                :category="content.menu"
                :service="services"
                :params="params"
                v-if="content && content.records && content.menu && (content.type == 'page') && ! isLoadingBlade"
            />

            <GridTemplate
                :category="content.menu"
                :menu="content.menu"
                :contents="content.records"
                :paginate="paginate"
                v-if="content && content.menu && content.records && (content.type == 'contents') && ! isLoadingBlade"
            />

            <MixedTemplate
                :content="content"
                :paginate="paginate"
                v-if="content && content.menu && content.records && (content.type == 'mixed') && ! isLoadingBlade"
            />


            <!-- Footer included here --> 
            <Footer 
                :params="params"
                :services="services"
                v-if="! isLoadingBlade"
            />
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import PageTemplate from '../layouts/PageTemplate.vue'
    import GridTemplate from '../layouts/GridTemplate.vue'
    import MixedTemplate from '../layouts/MixedTemplate.vue'
    import ErrorPage from './ErrorPage.vue'

    export default {
        name: 'MenuRoutePage',
        components: {
            Header,
            Footer,
            PageTemplate,
            GridTemplate,
            MixedTemplate,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: [],
                content: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'paginateNo']),
            getSearchUrl() {
                return this.$route
            }
        },
        methods: {
            ...mapActions(['getHomepageData', 'getMenuRoutePageContent', 'getPaginationRecords']),
            paginate(url) {
                this.errorPage = false;
                this.isLoadingBlade = true;
                this.getPaginationRecords(url).then((res) => {// get paginated records
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            loadPage() {
                this.errorPage = false;
                let data = { slug : this.$route.params.slug, limit: this.paginateNo };
                this.getMenuRoutePageContent(data).then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services; // set blog contents
                this.content = data.content;// get menu content
                setTimeout(() => { this.isLoadingBlade = false; }, 1000)
            }
        },
        created() {
            this.loadPage(); // get page contents
        }
    }
</script>

<style lang="scss" scoped>

</style>