<template>
    <div v-if="record && category">
        <!-- Page Header Start -->
        <div 
            class="container-fluid page-header mb-5 wow fadeIn" 
            data-wow-delay="0.1s" 
            v-if="record && category"
        >
            <div class="container">
                <h1 class="display-3 mb-4 animated slideInDown">
                    {{ record.title }}
                </h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">{{ category.title }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ record.title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->

        <!-- About Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-4 align-items-end mb-4" v-if="record">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img 
                            v-if="record.image"
                            class="img-fluid rounded" 
                            :src="record.image.url"
                            :alt="record.image.description" 
                        >
                    </div>
                    <div 
                        :class="'col-lg-' + (record.image ? 6 : 12) + ' wow fadeInUp'" 
                        data-wow-delay="0.3s"
                    >
                        <h1 class="display-5 mb-4">{{ record.title }}</h1>
                        <p class="mb-4" v-html="record.content"></p>
                    </div>
                </div>
                <div class="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="row g-4">
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <div class="h-100">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                        <i class="fa fa-times text-white"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h4>No Hidden Cost</h4>
                                        <span>{{ params.feature_hours }}</span>
                                    </div>
                                    <div class="border-end d-none d-lg-block"></div>
                                </div>
                                <div class="border-bottom mt-4 d-block d-lg-none"></div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div class="h-100">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                        <i class="fa fa-users text-white"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h4>Dedicated Team</h4>
                                        <span>{{ params.call_now }}</span>
                                    </div>
                                    <div class="border-end d-none d-lg-block"></div>
                                </div>
                                <div class="border-bottom mt-4 d-block d-lg-none"></div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div class="h-100">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                        <i class="fa fa-phone text-white"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h4>24/7 Available</h4>
                                        <span>{{ params.partners }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>

<script>
    export default {
        name: 'PageTemplate',
        props: ['record', 'category', 'params']
    }
</script>

<style lang="scss" scoped>

</style>