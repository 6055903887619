<template>
    <div>
        <div v-if="! errorPage">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
            />
            <!-- end:header-top -->
            

            <PageTemplate
                :record="record.page"
                :category="record.page"
                :service="services"
                :params="params"
                v-if="record && record.page && ! isLoadingBlade"
            />


            <!-- Footer included here -->
            <Footer 
                :params="params"
                :services="services"
                v-if="! isLoadingBlade"
            />
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import PageTemplate from '../layouts/PageTemplate.vue'
    import ErrorPage from './ErrorPage.vue'

    export default {
        name: 'OtherContentSinglePage',
        components: {
            Header,
            Footer,
            PageTemplate,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: [],
                record: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'amadeusUrl']),
            getSearchUrl() {
                let path = this.$route.fullPath;
                let splitted = path.split('?');
                let url = splitted && splitted.length ? splitted[1] : false
                return url ? (this.amadeusUrl + url) : false
            }
        },
        methods: {
            ...mapActions(['getOtherContentSinglePage']),
            loadPage() {
                this.errorPage = false;
                let data = { slug : this.$route.name };
                this.getOtherContentSinglePage(data).then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services; // set service contents
                this.record = data.page;// get page content
                setTimeout(() => { this.isLoadingBlade = false; }, 1000)
            }
        },
        created() {
            this.loadPage(); // get page contents
        }
    }
</script>

<style lang="scss" scoped>

</style>